import * as React from "react";
import {
  Container,
  Form,
  FormControl,
  InputGroup,
  Row,
  Button,
} from "react-bootstrap";
import $ from "jquery";
import Layout from "../components/Layout";

function Timer() {
  const [seconds, setSeconds] = React.useState(59);
  React.useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds("00:00");
    }
  });
  return (
    <div className="mb-4 text-start ms-xl-5 ms-lg-5 ms-3 ps-2">
      <span>({seconds})</span>
    </div>
  );
}

export default function UserConfirmationPage() {
  const elemRefs = [];
  const autoTab = (e) => {
    const BACKSPACE_KEY = 8;
    const DELETE_KEY = 46;
    let tabindex = $(e.target).attr("data-index") || 0;
    tabindex = Number(tabindex);
    let elem = null;
    if (e.keyCode === BACKSPACE_KEY) {
      elem = tabindex > 0 && elemRefs[tabindex - 1];
    } else if (e.keyCode !== DELETE_KEY) {
      elem = tabindex < elemRefs.length - 1 && elemRefs[tabindex + 1];
    }
    if (elem) {
      elem.current.focus();
    }
  };

  const Input = (props) => {
    const ref = React.createRef();
    elemRefs.push(ref);
    return (
      <FormControl
        data-index={props.index}
        ref={ref}
        maxLength={1}
        onKeyUp={props.autoTab}
      />
    );
  };

  const blocks = Array.from({ length: 4 }, (element, index) => (
    <InputGroup className="mb-2 w-auto ms-0">
      <Input key={index} index={index} autoTab={autoTab} />
    </InputGroup>
  ));

  return (
    <Layout>
      <main>
        <Container>
          <section>
            <div className="col-xl-5 col-lg-5 col-md-12 pt-3 mx-auto text-center">
              <h1 className="fs-48 fx-blod mb-5">Register as new user</h1>
              <img src="../img/influencer-register.svg" alt="register" />
              <h5 className="fw-bold fs-36 mt-4">Register</h5>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-12 pb-5 mx-auto text-center">
              <Form className="mt-4">
                <div className="col-xl-12 col-md-12 register-form">
                  <Row className="nav-link justify-content-center d-flex">
                    {blocks}
                  </Row>
                </div>
                <Timer />
                <Button
                  variant="primary"
                  className="w-100 fs-14 fw-bold h-52"
                  type="submit"
                >
                  Submit
                </Button>
              </Form>
            </div>
          </section>
        </Container>
      </main>
    </Layout>
  );
}
